import * as api from '../api';
import {
  showLoadingOverlay,
  hiddenLoadingOverlay,
} from './app';
import {
  GET_USER_DATA,
  SET_REPORT_DATASOURCE,
  SET_USER_AGREEMENT,
} from './ActionTypes';
import { Translate } from '../../Share/components';
import { processSingleTableData } from './dataSource';

export function onGetUserData() {
  return (dispatch, getState) => {
    try {
      const state = getState();
      const userName = state.user.name;
      if (userName === '') {
        dispatch(showLoadingOverlay());
        api.getUserData(1201)
          .then((response) => {
            const userData = response.data;
            userData.agreements = [{ id: 0, name: Translate({ content: 'report.all' }) }, ...userData.agreements];
            dispatch(hiddenLoadingOverlay());
            dispatch({
              type: GET_USER_DATA,
              userData,
            });
            localStorage.setItem('USER_DATA', JSON.stringify(userData));
            localStorage.setItem('SELECTED_AGREEMENT', JSON.stringify(userData.agreements[0]));
            dispatch({
              type: SET_USER_AGREEMENT,
              selectedAgreement: userData.agreements[0],
            });
          }, (error) => {
            dispatch(hiddenLoadingOverlay());
          });
      }
    } catch (error) {
      dispatch(hiddenLoadingOverlay());
    }
  };
}

function filterReportsDataByAgreement(reportDataSourceIds, dataSources, reportId, selectedAgreement, dispatch, tables, isTableLeveldataSource = false) {
  return new Promise(() => reportDataSourceIds.forEach(async (dataSourceId) => {
    if (isTableLeveldataSource) {
      const processData = tables.map((tableItem) => {
        const filteredData = (dataSources.sources[dataSourceId].groupedData[tableItem.groupColumns] || [])
          .filter(x => (
            selectedAgreement.id === 0 ?
              true :
              x.agreementId === selectedAgreement.id));
        return processSingleTableData(tableItem, filteredData, tables, reportId, dispatch);
      });
      dispatch({
        type: SET_REPORT_DATASOURCE,
        dataReadyFlags: tables.map(x => true),
        reportId,
        processData,
      });
    } else {
      const filteredData = dataSources.sources[dataSourceId].crossFilterData
        .filter(x => (
          selectedAgreement.id === 0 ?
            true :
            x.agreementId === selectedAgreement.id));
      const processData = tables.map(x => processSingleTableData(x, filteredData, tables, reportId, dispatch));
      dispatch({
        type: SET_REPORT_DATASOURCE,
        reportId,
        dataReadyFlags: tables.map(x => true),
        processData,
      });
    }
    dispatch(hiddenLoadingOverlay());
  }));
}

export function onSelectedAggremenChanged(selectedAgreement, reportId, dataSources) {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: SET_USER_AGREEMENT,
      selectedAgreement,
    });
    dispatch(showLoadingOverlay());
    if (reportId !== 0) {
      const { tables, isTableLeveldataSource } = state.reportDetails.reports[reportId];
      const reportDataSourceIds = state.reportDetails.reports[reportId].dataSources || [];

      await filterReportsDataByAgreement(
        reportDataSourceIds,
        state.dataSources, reportId, selectedAgreement,
        dispatch, tables, isTableLeveldataSource,
      );
      const nonProcessedReports = Object.values(state.reportDetails.reports)
        .filter(reportItem => reportItem.id !== reportId);
      nonProcessedReports.forEach(async (reportItem) => {
        await filterReportsDataByAgreement(
          reportDataSourceIds,
          state.dataSources, reportItem.id,
          selectedAgreement, dispatch, reportItem.tables, reportItem.isTableLeveldataSource,
        );
      });
    }
    if (dataSources.length > 0) {
      dataSources.forEach((dataSourceId) => {
        const reportsHavingSameDataSource = Object.values(state.reportDetails.reports)
          .filter(reportItem => reportItem.dataSources.includes(dataSourceId)) || [];
        reportsHavingSameDataSource.forEach(async (reportItem) => {
          await filterReportsDataByAgreement(
            [dataSourceId],
            state.dataSources, reportItem.id,
            selectedAgreement, dispatch, reportItem.tables, reportItem.isTableLeveldataSource,
          );
        });
      });
      const nonProcessedDataSources = Object.values(state.dataSources.sources)
        .filter(sourceItem => !dataSources.includes(sourceItem.id));
      nonProcessedDataSources.forEach((dataSource) => {
        const reportsHavingSameDataSource = Object.values(state.reportDetails.reports)
          .filter(reportItem => reportItem.dataSources.includes(dataSource.id)) || [];
        reportsHavingSameDataSource.forEach(async (reportItem) => {
          await filterReportsDataByAgreement(
            [dataSource.id],
            state.dataSources, reportItem.id,
            selectedAgreement, dispatch, reportItem.tables, reportItem.isTableLeveldataSource,
          );
        });
      });
    }
  };
}

